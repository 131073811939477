import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import LanguageSelector from './LanguageSelector';

function NavBar() {
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener('scroll', scrollHandler);

  return (
    <Navbar fixed="top" expand="md" className={navColour ? 'sticky' : 'navbar'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignContent: 'flex-end',
        }}
      >
        <LanguageSelector />
      </div>
    </Navbar>
  );
}

export default NavBar;
