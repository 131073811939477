import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  SiAmazonaws,
  SiApachemaven,
  SiDocker,
  SiJenkins,
  SiJetbrains,
  SiJira,
  SiLinux,
  SiPostman,
  SiSlack,
  SiVisualstudiocode,
  SiNewrelic,
  SiGrafana,
} from 'react-icons/si';
import { DiGit, DiGithub, DiBitbucket } from 'react-icons/di';

function Toolstack() {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
        <h6>Linux</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJetbrains />
        <h6>IDE Jetbrains</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <h6>Visual Code</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <h6>Postman</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
        <h6>Slack</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <h6>Git</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiGithub />
        <h6>Github</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiBitbucket />
        <h6>Bitbucket</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiNewrelic />
        <h6>New Relic</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiGrafana />
        <h6>Grafana</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiApachemaven />
        <h6>Apache Maven</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <h6>Docker</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJira />
        <h6>Jira</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <h6>AWS</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJenkins />
        <h6>Jenkins</h6>
      </Col>
    </Row>
  );
}

export default Toolstack;
