import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ImPointRight } from 'react-icons/im';
import { useTranslation } from 'react-i18next';
import { processSpanWithColor, processStrongWithColor } from '../../Helpers/Helpers';

function HomeContent() {
  const { t } = useTranslation();

  const activitiesLikeList = t('activitiesLikeList', { returnObjects: true });

  return (
    <Container>
      <Row>
        <Col md={8} className="home-about-description">
          <h1 style={{ fontSize: '2.6em' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: processStrongWithColor(
                  t('allowMeIntroduceMyself', { returnObjects: true }),
                  'purple',
                ),
              }}
            />
          </h1>
          <br />
          <blockquote className="blockquote mb-0">
            <p style={{ textAlign: 'justify' }}>
              {t('paragraphSummary1')}
              <br />
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: processSpanWithColor(
                    t('paragraphSummary2', { returnObjects: true }),
                    'purple',
                  ),
                }}
              />
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: processSpanWithColor(
                    t('paragraphSummary3', { returnObjects: true }),
                    'purple',
                  ),
                }}
              />
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: processSpanWithColor(
                    t('paragraphSummary4', { returnObjects: true }),
                    'purple',
                  ),
                }}
              />
              <br />
              <br />
              {t('activitiesLike')}
            </p>

            <ul>
              {activitiesLikeList &&
                activitiesLikeList.map((el, idx) => (
                  <li key={idx} className="about-activity">
                    <ImPointRight /> {el}
                  </li>
                ))}
            </ul>

            <p style={{ color: 'rgb(155 126 172)' }}>{t('epigraphPhrase')}</p>
            <footer className="blockquote-footer">Hemicharly Thiago</footer>
          </blockquote>
        </Col>
      </Row>
    </Container>
  );
}

export default HomeContent;
