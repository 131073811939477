import React from 'react';
import { useTranslation } from 'react-i18next';
import ptImg from '../Assets/pt_flag.png';
import enImg from '../Assets/usa_flag.png';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <button onClick={() => changeLanguage('pt')} title="Português">
        <img src={ptImg} alt="Português" width="35" height="30" />
      </button>
      <button onClick={() => changeLanguage('en')} title="English">
        <img src={enImg} alt="English" width="35" height="30" />
      </button>
    </>
  );
}

export default LanguageSelector;
