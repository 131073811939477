import React from 'react';
import { Container } from 'react-bootstrap';
import Particle from '../Particle';
import HomeContent from './HomeContent';
import HomeStacks from './HomeStacks';
import HomeTitle from './HomeTitle';

function Home() {
  return (
    <>
      <section>
        <Container fluid className="home-section" id="home">
          <Particle />
          <HomeTitle />
          <HomeContent />
        </Container>
      </section>

      <section>
        <Container fluid className="about-section">
          <Particle />
          <HomeStacks />
        </Container>
      </section>
    </>
  );
}

export default Home;
