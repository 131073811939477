export const processStrongWithColor = (valueHtml, className = 'purple') => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = valueHtml;
  tempElement.querySelectorAll('strong').forEach((strong) => {
    strong.classList.add(className);
  });
  return tempElement.innerHTML;
};

export const processSpanWithColor = (valueHtml, className = 'purple') => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = valueHtml;
  tempElement.querySelectorAll('span').forEach((span) => {
    span.classList.add(className);
  });
  return tempElement.innerHTML;
};
