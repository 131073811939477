import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Type from './Type';
import homeLogo from '../../Assets/home-main.svg';
import { useTranslation } from 'react-i18next';
import { processStrongWithColor } from '../../Helpers/Helpers';

function HomeTitle() {
  const { t } = useTranslation();

  return (
    <Container className="home-content">
      <Row>
        <Col md={7} className="home-header">
          <h1 style={{ paddingBottom: 15 }} className="heading">
            {t('hello')}
            <span className="wave" role="img" aria-labelledby="wave">
              👋🏻
            </span>
          </h1>

          <h1 className="heading-name">
            <div
              dangerouslySetInnerHTML={{
                __html: processStrongWithColor(t('myNameIs', { returnObjects: true }), 'main-name'),
              }}
            />
          </h1>

          <div style={{ marginTop: 45, paddingLeft: 45, textAlign: 'left' }}>
            <Type />
          </div>
        </Col>

        <Col md={5} style={{ padding: 20, marginTop: 20 }}>
          <img src={homeLogo} alt="home pic" className="img-fluid" style={{ maxHeight: '450px' }} />
        </Col>
      </Row>
    </Container>
  );
}

export default HomeTitle;
