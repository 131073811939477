import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import laptopImg from '../../Assets/about.png';
import Techstack from './Techstack';
import Toolstack from './Toolstack';
import { AiFillGithub } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { processSpanWithColor, processStrongWithColor } from '../../Helpers/Helpers';
import { useTranslation } from 'react-i18next';

function HomeStacks() {
  const { t } = useTranslation();

  return (
    <Container>
      <Row style={{ justifyContent: 'center', padding: '5px' }}>
        <Col md={5} style={{ paddingTop: '120px', paddingBottom: '50px' }} className="about-img">
          <img src={laptopImg} alt="about" className="img-fluid" />
        </Col>

        <h1 className="project-heading">
          <div
            dangerouslySetInnerHTML={{
              __html: processStrongWithColor(
                t('techSlackTitle', { returnObjects: true }),
                'purple',
              ),
            }}
          />
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <div
            dangerouslySetInnerHTML={{
              __html: processStrongWithColor(
                t('toolsSlackTitle', { returnObjects: true }),
                'purple',
              ),
            }}
          />
        </h1>

        <Toolstack />

        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t('findMeAt')}</h1>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: processSpanWithColor(t('talkTome', { returnObjects: true }), 'purple'),
                }}
              />
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/hemicharly"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://br.linkedin.com/in/hemicharly-thiago-2a003a140"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default HomeStacks;
