import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CgCPlusPlus } from 'react-icons/cg';
import { DiJava, DiJavascript1, DiMongodb, DiNodejs, DiPython, DiReact } from 'react-icons/di';
import {
  SiAmazoncloudwatch,
  SiAmazondynamodb,
  SiAmazonec2,
  SiAmazonecs,
  SiAmazons3,
  SiAmazonsimpleemailservice,
  SiAmazonsqs,
  SiApachekafka,
  SiAwsfargate,
  SiAwslambda,
  SiFirebase,
  SiKubernetes,
  SiMysql,
  SiOracle,
  SiPostgresql,
  SiRabbitmq,
  SiRedis,
  SiSpringboot,
} from 'react-icons/si';

function Techstack() {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <h6>Python</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <h6>Java</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpringboot />
        <h6>Spring Boot</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <h6>JavaScript</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <h6>NodeJs</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
        <h6>C++</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <h6>React</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <h6>MongoDB</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <h6>Firebase</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
        <h6>Redis</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazondynamodb />
        <h6>AWS DynamoDB</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <h6>PostgreSQL</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
        <h6>MySQL</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiOracle />
        <h6>Oracle</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazons3 />
        <h6>AWS S3</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiApachekafka />
        <h6>Kafka</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRabbitmq />
        <h6>RabbitMQ</h6>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonsqs />
        <h6>AWS SQS</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonsimpleemailservice />
        <h6>AWS SES</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazoncloudwatch />
        <h6>AWS CloudWatch</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonec2 />
        <h6>AWS EC2</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonecs />
        <h6>AWS ECS</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
        <h6>Kubernetes</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAwsfargate />
        <h6>AWS Fargate</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAwslambda />
        <h6>AWS Lambda</h6>
      </Col>
    </Row>
  );
}

export default Techstack;
